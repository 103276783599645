import React, { ReactElement } from 'react';
import { URL_PAGES } from './url.const';
import {
  CUD_ROLES,
  ADMIN_ROLES,
  AUTH_FEATURES,
  AUTH_ROIDS,
  DATA_HOLDER_TESTER_ROLES,
  AUTH_ROLES,
  AUTH_PERMISSIONS,
} from './authorization.const';

import {
  HomeIcon,
  AdminIcon,
  BinocularsIcon,
  BookIcon,
  Chart2Icon,
  ConsentIcon,
  DashboardIcon,
  DataHolderIcon,
  ExcahngeIcon,
  UseCaseIcon,
  GranteeIcon,
} from '../../components/atoms/icons/icons.atom';

export type MenuItem = {
  label: string;
  selected?: boolean;
  icon?: ReactElement;
  path?: string;
  children?: MenuItem[];
  mustHaveAuthorization?: string[];
};

export const navMenuItems: MenuItem[] = [
  {
    label: URL_PAGES.HOME.label,
    icon: <HomeIcon />,
    path: URL_PAGES.HOME.url,
  },
  {
    label: 'Exchange',
    icon: <ExcahngeIcon />,
    path: URL_PAGES.EXCH_HOME.url,
    mustHaveAuthorization: [...ADMIN_ROLES, AUTH_ROLES.CDR_READ_WRITE_MOGO_ACCESS],
    children: [
      {
        label: URL_PAGES.EXCH_HOME.label,
        path: URL_PAGES.EXCH_HOME.url,
        mustHaveAuthorization: [...ADMIN_ROLES, AUTH_ROLES.CDR_READ_WRITE_MOGO_ACCESS],
      },

      {
        label: 'MogoPlus',
        path: URL_PAGES.MOGO_HOME.url,
        mustHaveAuthorization: [...ADMIN_ROLES, AUTH_ROLES.CDR_READ_WRITE_MOGO_ACCESS, AUTH_ROIDS.MOGO_ROID],
        children: [
          {
            label: 'Search',
            path: URL_PAGES.MOGO_INSIGHT_RPT_SEARCH.url,
          },
          {
            label: 'Email',
            path: URL_PAGES.MOGO_INSIGHT_RPT_SEND_EMAIL.url,
          },
          {
            label: 'Create',
            path: URL_PAGES.MOGO_INSIGHT_RPT_CREATE.url,
          },
        ],
      },
    ],
  },
  {
    label: 'Explore data',
    icon: <BinocularsIcon />,
    path: URL_PAGES.EXPLORE_HOME.url,
    mustHaveAuthorization: ADMIN_ROLES.concat(DATA_HOLDER_TESTER_ROLES),
    children: [
      { label: URL_PAGES.EXPLORE_ENERGY.label, path: URL_PAGES.EXPLORE_ENERGY.url },
      { label: URL_PAGES.EXPLORE_BANKING.label, path: URL_PAGES.EXPLORE_BANKING.url },
    ],
  },
  {
    label: URL_PAGES.CONSENT.label,
    icon: <ConsentIcon />,
    path: URL_PAGES.CONSENT.url,
    children: [
      { label: URL_PAGES.CONSENT.label, path: URL_PAGES.CONSENT.url },
      {
        label: URL_PAGES.CONSENT_CREATE.label,
        path: URL_PAGES.CONSENT_CREATE.url,
        mustHaveAuthorization: ADMIN_ROLES,
      },
    ],
  },
  {
    label: 'Use cases',
    icon: <UseCaseIcon />,
    path: URL_PAGES.USE_CASE_LIST.url,
    children: [
      { label: URL_PAGES.USE_CASE_LIST.label, path: URL_PAGES.USE_CASE_LIST.url },
      {
        label: URL_PAGES.USE_CASE_CREATE.label,
        path: URL_PAGES.USE_CASE_CREATE.url,
        mustHaveAuthorization: CUD_ROLES,
      },
    ],
  },
  {
    label: 'Data holders',
    icon: <DataHolderIcon />,
    path: URL_PAGES.DH_STATUS.url,
    children: [
      { label: URL_PAGES.DH_STATUS.label, path: URL_PAGES.DH_STATUS.url },
      { label: URL_PAGES.PROD_EXPLORER.label, path: URL_PAGES.PROD_EXPLORER.url },
      {
        label: URL_PAGES.DOC_DATA_HOLDER_TESTING.label,
        path: URL_PAGES.DOC_DATA_HOLDER_TESTING.url,
        mustHaveAuthorization: [AUTH_FEATURES.DATA_HOLDER_TESTING],
      },
      {
        label: URL_PAGES.DATA_COLLECTIONS.label,
        path: URL_PAGES.DATA_COLLECTIONS.url,
        mustHaveAuthorization: ADMIN_ROLES,
      },
      {
        label: URL_PAGES.DATA_HOLDER_INTERACTIONS.label,
        path: URL_PAGES.DATA_HOLDER_INTERACTIONS.url,
      },
      {
        label: URL_PAGES.DATA_HOLDER_SESSIONS.label,
        path: URL_PAGES.DATA_HOLDER_SESSIONS.url,
      },
      {
        label: URL_PAGES.VALIDATE_ARRANGEMENT.label,
        path: URL_PAGES.VALIDATE_ARRANGEMENT.url,
      },
    ],
  },
  {
    label: 'Grantees',
    icon: <GranteeIcon />,
    path: URL_PAGES.GRANTEE_LIST.url,
    mustHaveAuthorization: ADMIN_ROLES,
  },
  {
    label: 'Consent dashboard',
    icon: <DashboardIcon />,
    path: URL_PAGES.DASHBOARD.url,
    mustHaveAuthorization: ADMIN_ROLES,
    children: [
      { label: URL_PAGES.DASHBOARD_AUTH.label, path: URL_PAGES.DASHBOARD_AUTH.url },
      { label: URL_PAGES.DASHBOARD_ENV.label, path: URL_PAGES.DASHBOARD_ENV.url },
      { label: URL_PAGES.DASHBOARD_THEME.label, path: URL_PAGES.DASHBOARD_THEME.url },
    ],
  },

  {
    label: 'Charts',
    icon: <Chart2Icon />,
    path: URL_PAGES.CHARTS.url,
    children: [
      {
        label: URL_PAGES.CHARTS.label,
        path: URL_PAGES.CHARTS.url,
      },
      {
        label: URL_PAGES.CHARTS_2.label,
        path: URL_PAGES.CHARTS_2.url,
      },
    ],
  },
  {
    label: 'Docs',
    icon: <BookIcon />,
    path: URL_PAGES.API_DOC_EXCH.url,
    children: [
      { label: URL_PAGES.API_DOC_EXCH.label, path: URL_PAGES.API_DOC_EXCH.url, mustHaveAuthorization: ADMIN_ROLES },
      { label: URL_PAGES.API_DOC_INSIGHTS.label, path: URL_PAGES.API_DOC_INSIGHTS.url },
      {
        label: URL_PAGES.API_DOC_CUSTOM_SV_INSIGHTS.label,
        path: URL_PAGES.API_DOC_CUSTOM_SV_INSIGHTS.url,
        mustHaveAuthorization: [AUTH_PERMISSIONS.INSIGHTS_SV],
      },
      { label: URL_PAGES.DOC_SANDBOX_DATA_HOLDERS.label, path: URL_PAGES.DOC_SANDBOX_DATA_HOLDERS.url },
    ],
  },
  {
    label: 'Admin',
    icon: <AdminIcon />,
    path: URL_PAGES.CONSENT_DASHBOARD_CONFIG.url,
    mustHaveAuthorization: ADMIN_ROLES,
    children: [
      {
        label: 'Dashboard settings',
        path: URL_PAGES.CONSENT_DASHBOARD_CONFIG.url,
        mustHaveAuthorization: ADMIN_ROLES,
      },
      {
        label: URL_PAGES.SOFTWARE_PRODUCTS.label,
        path: URL_PAGES.SOFTWARE_PRODUCTS.url,
        mustHaveAuthorization: ADMIN_ROLES,
      },
      {
        label: URL_PAGES.DYNAMIC_FORMS.label,
        path: URL_PAGES.DYNAMIC_FORMS.url,
        mustHaveAuthorization: ADMIN_ROLES,
      },
      {
        label: 'New Tenant',
        path: URL_PAGES.TENANT_SETUP.url,
        mustHaveAuthorization: ADMIN_ROLES,
      },
    ],
  },
];
