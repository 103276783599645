import * as React from 'react';
import { Autocomplete, Box, FormControl, MenuItem, TextField } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import { useAuth } from 'react-oidc-context';
import { useUser } from '../../../app-logic/context/user.context';

type MenuItem = {
  label: string;
  roid: string;
  active: boolean;
};

interface ExtraParams {
  audience: string;
}

export const TenantSelector = () => {
  const theme = useTheme();
  const auth = useAuth();
  const { resourceOwnerId, tenants } = useUser();

  const dropdownTenants: MenuItem[] = tenants.map((tenant) => {
    const active = tenant.r === resourceOwnerId ? true : false;
    return {
      label: tenant.n,
      roid: tenant.r,
      active,
    };
  });

  const activeTenant = dropdownTenants.find((t) => {
    return t.active === true;
  });

  const handleChange = (roid: string) => {
    const extraParams: ExtraParams = JSON.parse(`${process.env.REACT_APP_EXTRA_QUERY_PARAMS}`);
    auth.signinRedirect({
      redirect_uri: process.env.REACT_APP_REDIRECT_URI,
      scope: process.env.REACT_APP_SCOPE,
      extraQueryParams: {
        roid: roid,
        audience: extraParams.audience,
      },
    });
  };

  return (
    <Box
      sx={{
        mr: 2,
        ml: 0,
        width: '100%',
        minWidth: 140,
        borderRadius: '4px',
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        [theme.breakpoints.up('sm')]: {
          marginLeft: theme.spacing(3),
          width: 'auto',
        },
        '&:hover': {
          backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
      }}
    >
      {resourceOwnerId && tenants && (
        <>
          <FormControl fullWidth>
            <Autocomplete
              size="small"
              sx={{
                width: '350px',
                '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': { py: '3px' },
                '& input': { color: 'white' },
                '& button': { color: 'white' },
              }}
              disablePortal
              id="tenant-dropdown"
              options={dropdownTenants}
              renderInput={(params) => <TextField {...params} />}
              defaultValue={activeTenant}
              onChange={(event: React.SyntheticEvent<Element, Event>, newValue: MenuItem | null) => {
                event.stopPropagation();
                if (newValue) {
                  handleChange(newValue.roid);
                }
              }}
            />
          </FormControl>
        </>
      )}
    </Box>
  );
};
