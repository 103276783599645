import React, { useLocation } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import { createContext, ReactElement, useContext, useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { useUser } from './user.context';

enum AnalyticsEvents {
  PAGE_LOAD = 'Page load',
  BUTTON_CLICK = 'Button click',
}

export enum AnalyticsComponentIds {
  INSIGHTS_RPT_CREATE_BT = 'Insights report create button',
  INSIGHTS_RPT_CREATE_EMAIL_BT = 'Insights report create email button',
  INSIGHTS_RPT_SEARCH_BT = 'Insights report search button',
  INSIGHTS_RPT_SEARCH_RESET_BT = 'Insights report search reset button',
  INSIGHTS_RPT_SEARCH_TOGGLE_BT = 'Insights report search toggle button',
  DH_SESSION_SEARCH_RESET_BT = 'Data Holder Sessions search reset button',
  DH_SESSION_SEARCH_TOGGLE_BT = 'Data Holder Sessions search toggle button',
  DH_INTERACTIONS_SEARCH_RESET_BT = 'Data Holder Interactions search reset button',
  DH_INTERACTIONS_SEARCH_TOGGLE_BT = 'Data Holder Interactions search toggle button',
  CONSENT_ADV_SEARCH_BT = 'Consent advanced search button',
  CONSENT_ADV_SEARCH_RESET_BT = 'Consent advanced search reset button',
  CONSENT_ADV_SEARCH_TOGGLE_BT = 'Consent advanced search toggle button',
  TUTORIAL_WALKTHROUGH_CONTINUE_BT = 'Tutorial walkthrough continue button',
}

export enum AnalyticsAction {
  BACK = 'Back',
  CLOSE = 'Close',
  CREATE = 'Create',
  NEXT = 'Next',
  OPEN = 'Open',
  RESET = 'Reset',
  SUBMIT = 'Submit',
}

interface ContextProps {
  trackButtonClick: (id: AnalyticsComponentIds, action: AnalyticsAction) => void;
  trackPageLoad: (path: string) => void;
}

const AnalyticsContext = createContext<ContextProps | undefined>(undefined);

const useAnalytics = () => {
  const context = useContext(AnalyticsContext);
  if (!context) {
    throw new Error('useAnalytics must be used within a AnalyticsContext');
  }
  return context;
};

interface ProviderProps {
  children: ReactElement | ReactElement[];
}

const AnalyticsProvider = ({ children }: ProviderProps) => {
  let userId = 'unknown-user';
  const location = useLocation();
  const auth = useAuth();
  const { tenantName, authorization, resourceOwnerId } = useUser();
  const user = auth.user;
  const mixpanelToken = process.env.REACT_APP_MIXPANEL_TOKEN;
  const [prevPathname, setPrevPathname] = useState('');

  const appVersion = process.env.REACT_APP_VERSION || 'no-version-set';

  useEffect(() => {
    if (user?.profile?.email) {
      userId = user.profile.email;
    }
    if (mixpanelToken) {
      mixpanel.init(mixpanelToken, { debug: true });
      mixpanel.set_config({ persistence: 'localStorage' });
      mixpanel.identify(userId);
    }
  }, []);

  useEffect(() => {
    const curPathname = location.pathname;
    if (prevPathname !== curPathname) {
      setPrevPathname(curPathname);
      trackPageLoad(location.pathname);
    }
  }, [location]);

  const track = (event: string, properties: Record<string, string | string[]>) => {
    if (mixpanelToken) {
      mixpanel.track(event, addCommonProperties(properties));
    }
  };

  /**
   * Mixpanel Rules
   *
   * Custom property sent to Mixpanel MUST be Capitalised
   */
  const addCommonProperties = (properties: Record<string, string | string[]>): Record<string, string | string[]> => {
    const withCommonProperties = {
      AppVersion: appVersion,
      TenantName: tenantName,
      ResourseOwnerId: resourceOwnerId,
      Path: location.pathname,
      Role: authorization,
      ...properties,
    };
    return withCommonProperties;
  };

  const trackPageLoad = (path: string) => {
    track(AnalyticsEvents.PAGE_LOAD, { Path: path });
  };

  const trackButtonClick = (id: AnalyticsComponentIds, action: AnalyticsAction) => {
    track(AnalyticsEvents.BUTTON_CLICK, { Action: action, 'Component ID': id });
  };

  return (
    <>
      <AnalyticsContext.Provider
        value={{
          trackButtonClick,
          trackPageLoad,
        }}
      >
        {children}
      </AnalyticsContext.Provider>
    </>
  );
};

export { AnalyticsProvider, useAnalytics };
